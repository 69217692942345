import { store } from "../redux/Store";
import { appBaseUrl, certificateBaseUrl, oApiBaseUrl, tokenBaseUrl } from "./EnvironmentConfig";

export const getAccessToken = () => {
    return store.getState().tokenSlice.accessToken;
};

export const getAdminAccessToken = () => {
  return store.getState().tokenSlice.adminAccessToken;
};

export const BaseURL = {
  openAPI: oApiBaseUrl as string,
  token: tokenBaseUrl as string,
  certificate: certificateBaseUrl as string,
  emisXBaseURL: appBaseUrl as string,
} as const