import React from "react";
import { Dispatch, UnknownAction } from "redux";
import { apiContainer } from "../api/APIContainer";
import { APIRepoKeys } from "../api/APIRepoKeys";
import { AdminPinValidationResult } from "../api/apiResultModels/AdminPinValidationResult";
import { ICommonRepository } from "../api/interfaces/ICommonRepository";
import { LocalStorageKey, Strings } from "../constants/StringConstant";
import { clearAllAndRefresh, sendUsageLogs } from "../helpers/CommonHelper";
import { DateFormat, getTodayDateTimeString } from "../helpers/DateTimeHelper";
import { resetConfigState } from "../redux/actions/ConfigAction";
import { resetGlobalState, setAPICallInProgress } from "../redux/actions/GlobalAction";
import { AppConfig } from "../utils/AppConfig";
import { LoginViewModel } from "./LoginViewModel";

export const AdminContainerViewModel = () => {
  const commonAPIRepository = apiContainer.get<ICommonRepository>(
    APIRepoKeys.COMMON_API_REPOSITORY
  );

  const [isShowPinView, setIsShowPinView] = React.useState<boolean>(true);
  const [isShowPinLockedView, setIsShowPinLockedView] = React.useState<boolean>(parseInt(localStorage.getItem(LocalStorageKey.AdminPinFailCount) ?? "0") === AppConfig.defaultValues.AdminPinMaxFailCount);
  const [showValidationMessage, setShowValidationMessage] = React.useState<boolean>(false);
  const [bannerMessage, setBannerMessage] = React.useState<string>(Strings.Admin.AdminLogin);
  const [bannerMessageAttemptLeft, setBannerMessageAttemptLeft] = React.useState<string>("");
  const [adminScreenTimeOutMessage, setAdminScreenTimeOutMessage] = React.useState<string>("");

  function validatePin(pin: string, dispatch: Dispatch<UnknownAction>) {
    dispatch(setAPICallInProgress(true));
    commonAPIRepository
      .validateAdminPin(pin)
      .then((response) => {
        const result: AdminPinValidationResult = JSON.parse(response);
        if (result.data?.attributes?.passwordMatched) {
          localStorage.setItem(LocalStorageKey.AdminPinFailCount, "0")
          setIsShowPinView(false);
          setIsShowPinLockedView(false);
          setShowValidationMessage(false);
          setBannerMessage(Strings.Admin.AdminLogin);
          setBannerMessageAttemptLeft("");
        } else {
          setShowValidationMessage(true);
          const adminPinFailCount = parseInt(localStorage.getItem(LocalStorageKey.AdminPinFailCount) ?? "0");
          localStorage.setItem(LocalStorageKey.AdminPinFailCount, JSON.stringify(adminPinFailCount + 1));
          setBannerMessageAttemptLeft(Strings.Admin.AttemptsRemaining.replace('##', `${AppConfig.defaultValues.AdminPinMaxFailCount - (adminPinFailCount + 1)}`));
          localStorage.setItem(LocalStorageKey.AdminPinFailMaxCountTime, getTodayDateTimeString(DateFormat.FullDateTime));
          if (adminPinFailCount + 1 === AppConfig.defaultValues.AdminPinMaxFailCount) {
            setBannerMessage(Strings.Admin.IncorrectPin);
            localStorage.setItem(LocalStorageKey.AdminPinFailMaxCountTime, getTodayDateTimeString(DateFormat.FullDateTime));
            setIsShowPinView(false);
            setIsShowPinLockedView(true);
          } else {
            setBannerMessage(Strings.Admin.IncorrectPinWithFailureAttempts.replace('##', `${AppConfig.defaultValues.AdminPinMaxFailCount}`));
          }
        }
      })
      .catch(() => {
        console.log("Error in validatePin");
      })
      .finally(() => {
        dispatch(setAPICallInProgress(false));
      });
  }

  const setTimeOutMessage = (message: string, timer?: number) => {
    setAdminScreenTimeOutMessage(message);
    if (timer) {
      setTimeout(() => {
        clearTimeOutMessage();
      }, timer);
    }
  };

  const clearTimeOutMessage = () => {
    setAdminScreenTimeOutMessage("");
  };

  const postUsageLogs = async (dispatch: Dispatch<UnknownAction>) => {
    if (sendUsageLogs()) {
      dispatch(setAPICallInProgress(true));
      await commonAPIRepository.postUsageLogs(dispatch);
      dispatch(setAPICallInProgress(false));
    }
  };

  function handleChangeKioskAction(dispatch: Dispatch<UnknownAction>, onChangeSuccess: () => void) {
    if (LoginViewModel().isAdminUserSessionValid()) {
      dispatch(resetConfigState());
      dispatch(resetGlobalState());
      localStorage.clear();
      onChangeSuccess();
    } else {
      clearAllAndRefresh();
    }
  }

  const validateToShowAdminPinView = () => {
    const adminPinFailMaxCountTime = localStorage.getItem(LocalStorageKey.AdminPinFailMaxCountTime)
    if (adminPinFailMaxCountTime) {
      const failMaxCountTime = new Date(adminPinFailMaxCountTime);
      const timeDifferenceInHours = (new Date().getTime() - failMaxCountTime.getTime()) / (1000);
      if (timeDifferenceInHours > AppConfig.defaultValues.AdminPinMaxFailCountResetTimeInSeconds) {
        localStorage.setItem(LocalStorageKey.AdminPinFailCount, "0")
        return true;
      } else {
        const adminPinFailCount = parseInt(localStorage.getItem(LocalStorageKey.AdminPinFailCount) ?? "0");
        return adminPinFailCount < AppConfig.defaultValues.AdminPinMaxFailCount;
      }
    } else {
      localStorage.setItem(LocalStorageKey.AdminPinFailCount, "0")
      return true;
    }
  }

  return {
    isShowPinView,
    showValidationMessage,
    bannerMessage,
    validatePin,
    adminScreenTimeOutMessage,
    setTimeOutMessage,
    clearTimeOutMessage,
    postUsageLogs,
    handleChangeKioskAction,
    bannerMessageAttemptLeft,
    isShowPinLockedView,
    validateToShowAdminPinView,
    setIsShowPinView,
    setIsShowPinLockedView,
  };
};