import { BaseURL } from "../utils/ApiRequestUtil";
import { ValidDomain } from "../utils/EnvironmentConfig";

export class AuthenticationManager {

    static isTestEnvironment(urlString : string): boolean {
        return urlString.endsWith(ValidDomain.Emistesting) || 
        urlString.endsWith(ValidDomain.Emishosting) ||
        urlString.endsWith(ValidDomain.Emishealth) ||
        urlString.endsWith(ValidDomain.EmishealthSolutions) ||
        urlString.endsWith(ValidDomain.EmisxUk);
    }

    static isCertificateEnv(): boolean {
        return (!this.isTestEnvironment(BaseURL.openAPI))
    }

}