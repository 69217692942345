/* MessageBanner.tsx */

import { ColorConstant } from "../../../constants/ColorConstant";
import { isNonEmpty } from "../../../helpers/StringHelper";
import { MessageBannerText, MessageBannerTitleText, MessageBannerView } from "../styles/StylesCommonView";


interface MessageBannerProps {
    message: string;
    title?: string;
    showValidationMessage?: boolean;
    flexDirection?: 'column' | 'row';
}

const MessageBanner: React.FC<MessageBannerProps> = (props: MessageBannerProps) => {
    return (
        <MessageBannerView style={{ backgroundColor: props.showValidationMessage ? ColorConstant.SatinPink : ColorConstant.BLIZZARD_BLUE, flexDirection: props.flexDirection ?? 'row' }}>
            <MessageBannerText style={{ color: props.showValidationMessage ? ColorConstant.Coralite : ColorConstant.BIG_SUR_BLUE_JADE }}>
                {props.message}
            </MessageBannerText>
            {isNonEmpty(props.title) ?
                <MessageBannerTitleText style={{ color: props.showValidationMessage ? ColorConstant.Coralite : ColorConstant.BIG_SUR_BLUE_JADE }}>
                    {props.title}
                </MessageBannerTitleText>
                : null
            }
        </MessageBannerView>

    );
};

export default MessageBanner;