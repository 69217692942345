import React from 'react';
import { ButtonText, Strings } from '../../../../constants/StringConstant';
import { AdminPinLockedContainerView, AdminPinLockedFooterButton, AdminPinLockedInfoText, StyledLockIcon } from './StylesAdminScreen';

export interface AdminPinLockedScreenProps {
    onHomePress: () => void;
}

const AdminPinLockedScreen: React.FC<AdminPinLockedScreenProps> = ({ onHomePress }) => {
    return (
        <AdminPinLockedContainerView data-testid="AdminPinLockedContainerView">
            <StyledLockIcon />
            <AdminPinLockedInfoText>{Strings.Admin.AdminPinMaxFailCountMessage}</AdminPinLockedInfoText>
            <AdminPinLockedFooterButton data-testid="AdminPinLocked-FooterButton-HomeAction" onClick={onHomePress}>
                {ButtonText.GoBackToHome}
            </AdminPinLockedFooterButton>
        </AdminPinLockedContainerView>
    );
};

export default AdminPinLockedScreen;
