/* BookAppointmentTypeSelection.tsx */

import { Grid } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { SlotType } from '../../../../../api/apiResultModels/KioskConfigurationResult';
import { Strings } from '../../../../../constants/StringConstant';
import HeaderQuestion from '../../../commonViews/HeaderQuestion';
import { BookAppointmentTypeSelectionContainer, BookAppointmentTypeSelectionDividerView, BookAppointmentTypeSelectionHeaderQuestionView, LargeGridButton } from '../../../styles/StylesBookAppointment';
import BookAppointmentTypeSelectionHeaderView from './BookAppointmentTypeSelectionHeaderView';

interface BookAppointmentTypeSelectionProps {
    patientName: string;
    handleNotYouAction: () => void;
    handleSlotTypeAction: (slotTypeId: string) => void;
    slotTypes: SlotType[];
}

const BookAppointmentTypeSelection: React.FC<BookAppointmentTypeSelectionProps> = (props: BookAppointmentTypeSelectionProps) => {
    const { t } = useTranslation();
    
    return (
        <BookAppointmentTypeSelectionContainer>
            <BookAppointmentTypeSelectionHeaderView patientName={props.patientName} handleNotYouAction={props.handleNotYouAction} />
            <BookAppointmentTypeSelectionDividerView />
            <BookAppointmentTypeSelectionHeaderQuestionView>
                <HeaderQuestion headerText={t(Strings.BookAppointment.SelectAppointmentType)} subHeaderText={''} />
            </BookAppointmentTypeSelectionHeaderQuestionView>
            <Grid container justifyContent={'center'} style={{ overflow: 'auto' }}>
                {props.slotTypes.map((slotType, index) => {
                    return (
                        <Grid item key={`${index}.${slotType.SlotTypeId}`}>
                            <LargeGridButton data-testid={`SlotTypeButton-${slotType.Description}`}
                                onClick={() => {
                                    props.handleSlotTypeAction(`${slotType.SlotTypeId}`);
                                }}
                                variant="contained"
                                aria-label={slotType.Description}
                            >
                                {slotType.Description}
                            </LargeGridButton>
                        </Grid>
                    );
                })}
            </Grid>
        </BookAppointmentTypeSelectionContainer>
    );
};

export default BookAppointmentTypeSelection;