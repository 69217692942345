import React from "react";
import { RouterName } from "../../../navigation/RouterName";

export const TopBarViewModel = () => {
    const [lastTapTime, setLastTapTime] = React.useState<number | null>(null);
    const [tapCount, setTapCount] = React.useState(0);
    const timerRef = React.useRef<NodeJS.Timeout | null>(null);
    const timeGapBetweenTap = 1000;
    const numberOfTaps = 2;

    const handleTap = (callback: () => void) => {
        const currentTime = Date.now();
        if (lastTapTime && currentTime - lastTapTime > timeGapBetweenTap) {
            setTapCount(1);
        } else if (tapCount + 1 === numberOfTaps) {
            if (![`${RouterName.KioskList}`].includes(window.location.pathname)) {
                callback();
            }
        } else {
            setTapCount((prev) => prev + 1);
        }
        setLastTapTime(currentTime);

        if (timerRef.current) {
            clearTimeout(timerRef.current);
        }
        timerRef.current = setTimeout(() => {
            setTapCount(0);
            setLastTapTime(null);
        }, timeGapBetweenTap);
    };

    return {
        lastTapTime, setLastTapTime,
        tapCount, setTapCount,
        handleTap,
    }
}