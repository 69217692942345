/* HomeScreen.tsx */

import { useMediaQuery, useTheme } from '@mui/material';
import { useFlags } from 'launchdarkly-react-client-sdk';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { apiContainer } from '../../../../api/APIContainer';
import { APIRepoKeys } from '../../../../api/APIRepoKeys';
import { KioskConfigurationResult } from '../../../../api/apiResultModels/KioskConfigurationResult';
import { SurveyResult } from '../../../../api/apiResultModels/SurveyResult';
import { ICommonRepository } from '../../../../api/interfaces/ICommonRepository';
import { IKioskRepository } from '../../../../api/interfaces/IKioskRepository';
import { ISurveyRepository } from '../../../../api/interfaces/ISurveyRepository';
import { ButtonText, ErrorMessage, Strings } from '../../../../constants/StringConstant';
import useIdleTimer from '../../../../customHooks/useIdleTimer';
import { getAllOrgs, getScreenTimeOutInMilliSeconds, getSelectedOrg, resetToDefaultLanguage, sendUsageLogs } from '../../../../helpers/CommonHelper';
import { isEqualIgnoreCase, isNonEmpty } from '../../../../helpers/StringHelper';
import { RouterName } from '../../../../navigation/RouterName';
import { setAnonymousSurveys, setKioskConfig } from '../../../../redux/actions/ConfigAction';
import { setAPICallInProgress } from '../../../../redux/actions/GlobalAction';
import { RootState, store, useAppDispatch, useAppSelector } from '../../../../redux/Store';
import { DD_APIEndPointName } from '../../../../utils/DDAPIEndpointName';
import { HomeViewModel } from '../../../../viewModels/HomeViewModel';
import { LoginViewModel } from '../../../../viewModels/LoginViewModel';
import APIFailScreen from '../../commonViews/APIFailScreen';
import Loader from '../../commonViews/Loader';
import TopBar from '../../commonViews/TopBar';
import { HomeScreenContainer } from '../../styles/StylesHome';
import HomeScreenOptions from './HomeScreenOptionsView';
import HomeScreenTopBanner from './HomeScreenTopBanner';

export interface AlertContent {
  title?: string
  message?: string
}

const kioskRepository = apiContainer.get<IKioskRepository>(
  APIRepoKeys.KIOSK_API_REPOSITORY,
);

const surveyRepository = apiContainer.get<ISurveyRepository>(
  APIRepoKeys.SURVEY_API_REPOSITORY,
);

const commonAPIRepository = apiContainer.get<ICommonRepository>(
  APIRepoKeys.COMMON_API_REPOSITORY,
);

const HomeScreen = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const modules = useAppSelector((state) => state.configSlice.modules)
  const kioskTitle = useAppSelector((state) => state.configSlice.kioskDetails?.title ?? '')
  const kioskLogo = useAppSelector((state) => state.configSlice.kioskDetails?.kioskLogo ?? '')
  const apiCallInProgress = useSelector((state: RootState) => state.globalSlice.apiCallInProgress);
  const [lastAPICall, setLastAPICall] = React.useState<string | undefined>(undefined)
  const [alertContent, setAlertContent] = React.useState<AlertContent | undefined>(undefined)
  const [showAlert, setShowAlert] = React.useState<boolean>(false)
  const { t } = useTranslation();
  const isShowHomeButton = getAllOrgs().length > 1
  const selectedOrg = getSelectedOrg();
  const slotTypes = useAppSelector((state) => state.configSlice.slotTypes ?? []).filter(slotType => `${slotType.organisationId}` === `${selectedOrg?.id}`)
  const anonymousSurveysFromConfig = useAppSelector((state) => state.configSlice.anonymousSurveysFromConfig) ?? []

  const screenTimeOut = useAppSelector((state) => state.configSlice.kioskDetails?.screenTimeOut)
  const { isIdle, timeRemaining } = useIdleTimer(
    getScreenTimeOutInMilliSeconds(screenTimeOut), 
    dispatch, 
    navigate, 
    isShowHomeButton,
  );
  const [timeOutMessageContent, setTimeOutMessageContent] = React.useState<string>('')

  React.useEffect(() => {
    if (isIdle && timeRemaining === 1000) {
      setTimeOutMessageContent(`${t(Strings.TimingOutIn)} ${timeRemaining / 1000}`)
      setTimeout(() => {
        setTimeOutMessageContent('')
      }, 1000);
    } else if (isIdle && timeRemaining && timeRemaining <= 3000) {
      setTimeOutMessageContent(`${t(Strings.TimingOutIn)} ${timeRemaining / 1000}`)
    } else {
      setTimeOutMessageContent('')
    }
  }, [dispatch, isIdle, navigate, t, timeRemaining])

  const handleCheckInAction = () => {
    navigate(RouterName.CheckIn);
  };

  const handleBookAppointmentAction = () => {
    navigate(RouterName.BookAppointment);
  };

  const siteMapAction = () => {
    navigate(RouterName.SiteMap);
  };

  const questionnairesAction = async () => {
    const kioskID = store.getState().globalSlice.currentKiosk?.id
    if (kioskID) {
      try {
        dispatch(setAPICallInProgress(true));
        const isValidSession = await LoginViewModel().validateSession(dispatch)
        if (!isValidSession) {
          return
        }
        const response = await surveyRepository.getAnonymousSurvey(kioskID)
        const result: SurveyResult = JSON.parse(response)
        dispatch(setAnonymousSurveys(result.data ?? []))
        navigate(RouterName.Survey, { state: { from: `${RouterName.Home}` } });
      } catch (error) {
        console.log('error => ', error)
      } finally {
        dispatch(setAPICallInProgress(false));
      }
    }
  };

  const theme = useTheme();
  const portrait = useMediaQuery('(orientation: portrait)');
  const mobile = useMediaQuery(theme.breakpoints.down('sm'));

  const allFlags = useFlags();
  console.log('=======allFlags------', allFlags);

  const postUsageLog = React.useCallback(async () => {
    try {
      if (sendUsageLogs()) {
        await commonAPIRepository.postUsageLogs(dispatch)
      }
    }
    catch (error) {
      console.log('error => ', error)
    }
  }, [dispatch])

  const fetchKioskConfig = React.useCallback(async () => {
    const selectedKiosk = store.getState().globalSlice.currentKiosk
    if (selectedKiosk?.id && isNonEmpty(selectedKiosk.id)) {
      setLastAPICall(DD_APIEndPointName.kioskConfiguration)
      dispatch(setAPICallInProgress(true));
      try {
        const responseString = await kioskRepository.getKioskConfiguration(`${selectedKiosk.id}`);
        const kioskConfigurationResult: KioskConfigurationResult = JSON.parse(responseString);
        HomeViewModel().setDDsetGlobalContextForSessionParams(kioskConfigurationResult)
        dispatch(setKioskConfig(kioskConfigurationResult))
        postUsageLog()
        setTimeout(() => {
          resetToDefaultLanguage(dispatch);
        }, 100);
      }
      catch (error) {
        const kioskConfigAvailable = (store.getState().configSlice.kioskDetails?.kioskId ?? 0) > 0
        if (!kioskConfigAvailable) {
          setAlertContent({ title: 'Failed', message: `Failed to get configuration for the kiosk "${selectedKiosk.name}". ${ErrorMessage.PleaseSpeakToAMemberOfOurReceptionTeam}` })
          setShowAlert(true)
        }
      }
      finally {
        dispatch(setAPICallInProgress(false));
      }
    } else {
      navigate(RouterName.SplashScreen)
    }
  }, [dispatch, navigate, postUsageLog])

  const validateAndFetchToken = React.useCallback(async () => {
    setLastAPICall(DD_APIEndPointName.Token)
    dispatch(setAPICallInProgress(true));
    LoginViewModel().validateSession(dispatch)
      .then(isValidSession => {
        dispatch(setAPICallInProgress(false));
        if (!isValidSession) {
          setAlertContent({ title: 'Failed', message: `Failed to fetch Token. ${ErrorMessage.PleaseSpeakToAMemberOfOurReceptionTeam}` })
          setShowAlert(true)
          return
        }
        fetchKioskConfig()
      })
      .catch(error => {
        console.log('error => ', error)
        dispatch(setAPICallInProgress(false));
        setAlertContent({ title: 'Failed', message: `Failed to get Token. ${ErrorMessage.PleaseSpeakToAMemberOfOurReceptionTeam}` })
        setShowAlert(true)
      })
  }, [dispatch, fetchKioskConfig])

  React.useEffect(() => {
    resetToDefaultLanguage(dispatch);
    validateAndFetchToken()
  }, [validateAndFetchToken, dispatch])

  function handleRetryAction() {
    setShowAlert(false)
    if (isEqualIgnoreCase(lastAPICall, DD_APIEndPointName.kioskConfiguration)) {
      fetchKioskConfig()
    } else if (isEqualIgnoreCase(lastAPICall, DD_APIEndPointName.Token)) {
      validateAndFetchToken()
    }
  }

  return (
    <HomeScreenContainer>

      <TopBar
        isShowHomeButton={isShowHomeButton}
        isShowBackOption={false}
        handleBackClick={() => { }}
        timeOutMessage={timeOutMessageContent}
      />

      {!showAlert ? <HomeScreenTopBanner kioskTitle={kioskTitle} kioskLogo={kioskLogo} /> : null}

      {!showAlert ?
        <HomeScreenOptions
          handleCheckInAction={handleCheckInAction}
          handleBookAppointmentAction={handleBookAppointmentAction}
          siteMapAction={siteMapAction}
          questionnairesAction={questionnairesAction}
          modules={modules}
          portrait={portrait}
          mobile={mobile}
          slotTypes={slotTypes}
          anonymousSurveysFromConfig={anonymousSurveysFromConfig}
        />
        : null
      }

      {showAlert && alertContent ?
        <APIFailScreen
          title={alertContent?.title ?? ''}
          information={alertContent?.message ?? ""}
          tryAgainAction={handleRetryAction}
          buttonText={t(ButtonText.TryAgain)}
        />
        : null
      }

      {apiCallInProgress ? <Loader showLoading={apiCallInProgress} /> : null}

    </HomeScreenContainer>
  );
};

export default HomeScreen;
