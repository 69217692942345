import { asyncWithLDProvider } from 'launchdarkly-react-client-sdk';
import { ldClientId } from '../utils/EnvironmentConfig';

export const LDConstants = {
  Windows7_KillSwitch: 'Windows7_KillSwitch',
  Sync_Interval: 'sync_interval',
  Kiosk_CRO: 'Kiosk_CRO'
};

const initLaunchDarkly = async () => {
  const clientSideIDENV = ldClientId as string;
  const LDProvider = await asyncWithLDProvider({
    clientSideID: clientSideIDENV, // To be replaced with production clientSideID when taking to production
    context: {
        "kind": "org",
        "key": "unknown-org",
    }
  });
  return LDProvider;
};

export default initLaunchDarkly;

