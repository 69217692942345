import React from "react";
import { useNavigate } from "react-router-dom";
import { ButtonText, Strings } from "../../../../constants/StringConstant";
import { clearAllAndRefresh } from "../../../../helpers/CommonHelper";
import { OrganizationUIEntity } from "../../../../models/OrganizationUIEntity";
import { RouterName } from "../../../../navigation/RouterName";
import { setCurrentOrg } from "../../../../redux/actions/GlobalAction";
import { resetClientToken } from "../../../../redux/actions/TokenAction";
import { useAppDispatch } from "../../../../redux/Store";
import { OrganisationViewModel } from "../../../../viewModels/OrganisationViewModel";
import APIFailScreen from "../../commonViews/APIFailScreen";
import HeaderQuestion from "../../commonViews/HeaderQuestion";
import MessageBanner from "../../commonViews/MessageBanner";
import TopBar from "../../commonViews/TopBar";
import { HeaderQuestionContainer } from "../../styles/StylesMatchingScreen";
import OrganisationListGridView from "./OrganisationListGridView";
import { OrganisationListContainerView, OrganisationView } from "./StylesOrganisation";

export interface OrgCredential {
    ServiceName: string,
    ClientId: string,
    ClientSecret: string,
}

export interface AlertContent {
    title?: string
    message?: string
}

const OrganisationListScreen: React.FC = () => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const hasFetchedOrganisations = React.useRef(false);

    const {
        getSavedOrganisations,
        organisations,
        saveOrganisations,
        handleOrgSelection,
        alertContent,
        showAlert
    } = OrganisationViewModel();

    const onSelectOrg = React.useCallback(async (selectedOrg: OrganizationUIEntity) => {
        dispatch(resetClientToken());
        dispatch(setCurrentOrg(selectedOrg))
        handleOrgSelection(selectedOrg, () => {
            navigate(RouterName.Home);
        });
    }, [dispatch, navigate, handleOrgSelection]);

    React.useEffect(() => {
        if (!hasFetchedOrganisations.current) {
            const allOrgUIEntities = getSavedOrganisations()
            if (allOrgUIEntities.length === 0) {
                navigate(RouterName.SplashScreen)
            } else if (allOrgUIEntities.length === 1) {
                onSelectOrg(allOrgUIEntities[0]);
            } else {
                saveOrganisations(allOrgUIEntities);
            }
        }
        hasFetchedOrganisations.current = true
    }, [getSavedOrganisations, navigate, onSelectOrg, saveOrganisations]);

    return (
        <OrganisationListContainerView>
            <TopBar
                isShowHomeButton={false}
                isShowBackOption={false}
                isShowLanguageOption={false}
                handleBackClick={() => { }}
                timeOutMessage={''}
            />
            {!showAlert ?
                <OrganisationView data-testid="OrganisationView">
                    {organisations.length > 0 ? <MessageBanner message={Strings.Home.Welcome} /> : null}
                    <HeaderQuestionContainer>
                        <HeaderQuestion data-testid="HeaderQuestion" headerText={organisations.length > 0 ? Strings.SelectOrganisation : ''} subHeaderText={''} />
                    </HeaderQuestionContainer>
                    <OrganisationListGridView data-testid="OrganisationListGridView" organisations={organisations} handleOrgSelection={onSelectOrg} />
                </OrganisationView>
                : null
            }
            {showAlert && alertContent ?
                <APIFailScreen data-testid="APIFailScreen"
                    title={alertContent?.title ?? ''}
                    information={alertContent?.message ?? ""}
                    buttonText={ButtonText.Ok}
                    tryAgainAction={() => clearAllAndRefresh()}
                />
                : null
            }
        </OrganisationListContainerView>
    )
}

export default OrganisationListScreen