
import { injectable } from "inversify";
import { BaseURL, getAccessToken } from "../../utils/ApiRequestUtil";
import { AppConfig } from "../../utils/AppConfig";
import { DD_APIEndPointName } from "../../utils/DDAPIEndpointName";
import { APIClientElectron } from "../APIClientElectron";
import { GetNonAnonymousSurveyParameters } from "../apiParameterModels/GetNonAnonymousSurveyParameters";
import { PostAnonymousSurveyParameters } from "../apiParameterModels/PostAnonymousSurveyParameters";
import { PostNonAnonymousSurveyFrequencyParameters } from "../apiParameterModels/PostNonAnonymousSurveyFrequencyParameters";
import { PostNonAnonymousSurveyParameters } from "../apiParameterModels/PostNonAnonymousSurveyParameters";
import { ISurveyRepository } from "../interfaces/ISurveyRepository";

@injectable()
export class SurveyRepository implements ISurveyRepository {

    getAnonymousSurvey(kioskID: string): Promise<string> {
        const params = { kioskID: kioskID, IsPublished: true };
        return new Promise((resolve, reject) => {
            const accessToken = `Bearer ${getAccessToken()}`
            APIClientElectron.getRequestWithOutCertificate(
                DD_APIEndPointName.GetAnonymousSurvey,
                `${BaseURL.emisXBaseURL}${AppConfig.emisxAPIUrlEndPoint.GetAnonymousSurvey}`,
                accessToken,
                params)
                .then(response => {
                    resolve(response);
                })
                .catch((error: Error) => {
                    reject(error);
                });
        });
    }

    postAnonymousSurvey(params: PostAnonymousSurveyParameters): Promise<string> {
        const accessToken = `Bearer ${getAccessToken()}`
        return new Promise((resolve, reject) => {
            APIClientElectron.postRequestWithOutCertificate(
                DD_APIEndPointName.PostAnonymousSurvey,
                `${BaseURL.emisXBaseURL}${AppConfig.emisxAPIUrlEndPoint.PostAnonymousSurvey}`,
                params, accessToken)
                .then(async response => {
                    resolve(response);
                })
                .catch((error: Error) => {
                    reject(error);
                });
        });
    }

    getNonAnonymousSurvey(params: GetNonAnonymousSurveyParameters): Promise<string> {
        return new Promise((resolve, reject) => {
            const accessToken = `Bearer ${getAccessToken()}`
            APIClientElectron.getRequestWithOutCertificate(
                DD_APIEndPointName.GetNonAnonymousSurvey,
                `${BaseURL.emisXBaseURL}${AppConfig.emisxAPIUrlEndPoint.GetNonAnonymousSurvey}`,
                accessToken,
                params)
                .then(response => {
                    resolve(response);
                })
                .catch((error: Error) => {
                    reject(error);
                });
        });
    }

    postNonAnonymousSurvey(params: PostNonAnonymousSurveyParameters): Promise<string> {
        return new Promise((resolve, reject) => {
            const accessToken = `Bearer ${getAccessToken()}`
            APIClientElectron.postRequestWithCertificate(
                DD_APIEndPointName.PostNonAnonymousSurvey,
                `${BaseURL.openAPI}${AppConfig.openApiUrlEndPoint.PostNonAnonymousSurvey}`,
                params, accessToken)
                .then(async response => {
                    resolve(response);
                })
                .catch((error: Error) => {
                    reject(error);
                });
        });
    }

    postNonAnonymousSurveyFrequency(params: PostNonAnonymousSurveyFrequencyParameters): Promise<string> {
        return new Promise((resolve, reject) => {
            const accessToken = `Bearer ${getAccessToken()}`
            APIClientElectron.postRequestWithOutCertificate(
                DD_APIEndPointName.PostNonAnonymousSurveyFrequency,
                `${BaseURL.emisXBaseURL}${AppConfig.emisxAPIUrlEndPoint.PostNonAnonymousSurveyFrequency}`,
                params, accessToken)
                .then(async response => {
                    resolve(response);
                })
                .catch((error: Error) => {
                    reject(error);
                });
        });
    }
}