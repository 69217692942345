import { injectable } from "inversify";
import { store } from "../../redux/Store";
import { BaseURL } from "../../utils/ApiRequestUtil";
import { AppConfig } from "../../utils/AppConfig";
import { appTenantId, B2CConstants } from "../../utils/EnvironmentConfig";
import { APIClientElectron } from "../APIClientElectron";
import { TokenParameters } from "../apiParameterModels/TokenParameters";
import { withRetry } from "../APIRetryUtil";
import { ITokenRepository } from "../interfaces/ITokenRepository";

@injectable()
export class TokenRepository implements ITokenRepository {
    getToken(): Promise<string> {
        const organisationId = store.getState().globalSlice.currentOrg?.id ?? ''
        const tokenRequestData: TokenParameters = {
            grant_type: B2CConstants.grantType,
            scope: B2CConstants.scope,
            organisation_id: `${organisationId}`
        }
        const apiURL = `${BaseURL.token}/${appTenantId as string}/${B2CConstants.policy}/${AppConfig.B2C_Token_Endpoint}`
        return withRetry(() => {
            return new Promise((resolve, reject) => {
                APIClientElectron.postTokenRequest(apiURL, tokenRequestData)
                    .then(async response => {
                        resolve(response);
                    })
                    .catch((error: Error) => {
                        reject(error)
                    })
            })
        }, AppConfig.retryCount.clientTokenRequest, AppConfig.retryDelay.clientTokenRequest);
    }
}