// src/authConfig.js

import { b2cAuthorityUrl, b2cClientId, b2cKnownAuthorityUrls, b2cRedirectUri, b2cScopes } from "./utils/EnvironmentConfig";

export const msalConfig = {
    auth: {
        clientId: b2cClientId as string,
        authority: b2cAuthorityUrl as string,
        knownAuthorities: (b2cKnownAuthorityUrls as string ?? "").split(","),
        redirectUri: b2cRedirectUri as string
    },
    cache: {
        cacheLocation: "sessionStorage",  // Can also use localStorage
        storeAuthStateInCookie: false,    // Set to true if you have issues on IE 11 or Edge
    }
};

export const loginRequest = {
    scopes: (b2cScopes as string ?? "").split(",")
};
