export const b2cClientId = process.env.REACT_APP_B2C_CLIENT_ID;
export const b2cAuthorityUrl = process.env.REACT_APP_B2C_AUTHORITY_URL;
export const b2cKnownAuthorityUrls = process.env.REACT_APP_B2C_KNOWN_AUTHORITY_URLS;
export const b2cRedirectUri = process.env.REACT_APP_B2C_REDIRECT_URI;
export const b2cScopes = process.env.REACT_APP_B2C_SCOPES;
export const appTenantId = process.env.REACT_APP_TENANT_ID;
export const ddApplicationId = process.env.REACT_APP_DATADOG_APPLICATIONID;
export const ddEnvironment = process.env.REACT_APP_DD_ENV;
export const ddClientToken = process.env.REACT_APP_DATADOG_CLIENTTOKEN;
export const ldClientId = process.env.REACT_APP_LD_CLIENTID;
export const oApiBaseUrl = process.env.REACT_APP_BASE_URL_OPEN_API;
export const tokenBaseUrl = process.env.REACT_APP_BASE_URL_CLIENT_TOKEN;
export const certificateBaseUrl = process.env.REACT_APP_BASE_URL_CERTIFICATE;
export const appBaseUrl = process.env.REACT_APP_BASE_URL_EMISX;

export const B2CConstants = {
    grantType: 'client_credentials',
    scope: `${tokenBaseUrl}/x/.default`,
    policy: `B2C_1A_ClientCredentials`,
}

export enum ValidDomain {
    Emistesting = '.emistesting.co.uk',
    Emishosting = '.emishosting.com',
    Emishealth = '.emishealth.com',
    EmishealthSolutions = '.emishealthsolutions.com',
    EmisxUk = '.emis-x.uk',
}
